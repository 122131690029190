<template>
  <div class="create-auction" v-if="ownedMedia.length">
    <h3>Create Auction</h3>
    <label for="select token id">Select Token Id</label>
    <select v-model="tokenId" name="select token id">
      <option disabled value="">Please select token id</option>
      <option
        v-for="token in ownedMedia"
        :key="token.id"
        :value="token.id"
        >{{ token.id }}
      </option>
    </select>
    <label for="auctionCurrency">Auction Currency</label>
    <input type="string" name="auctionCurrency" v-model="auctionCurrency">
    <label for="reservePrice">Reserve Price</label>
    <input type="string" name="reservePrice" v-model="reservePrice">
    <label for="durationDays">Duration Days</label>
    <input type="string" name="durationDays" v-model="durationDays">
    <button @click="createAuction">Create</button>
    <p>{{ createAuctionStatus }}</p>
  </div>
  <button class="refresh-button" @click="refresh">Refresh</button>
  <div class="manage-auctions" v-if="auctions">
    <h3>Manage Auctions</h3>
    <table class="auctions">
      <tr>
        <th>Auction Id</th>
        <th>Token Id</th>
        <th>Status</th>
        <th>Approved</th>
        <th></th>
      </tr>
      <tr
        class="auction"
        :class="auction.status.toLowerCase()"
        v-for="auction in auctions"
        :key="auction.id"
        >
        <td>{{ auction.id }}</td>
        <td>{{ auction.media.id }}</td>
        <td>{{ auction.status }}</td>
        <td>{{ auction.approved }}</td>
        <td>
          <button v-if="canCancel(auction)" @click="() => cancelAuction(auction.id)">Cancel</button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { getSigner, getZora, zoraGraphRinkeby, zoraGraph } from '../services.js'
import { AuctionHouse } from '@zoralabs/zdk'
import { gql } from 'graphql-request'
import { utils } from 'ethers'

// TODO: make it work on mainnet
// TODO: specify olta wallet address
// TODO: instructions

export default defineComponent({
  setup() {
    const ownedMedia = ref([])
    const tokenId = ref(null)
    const reservePrice = ref(null)
    const auctionCurrency = ref('0x0000000000000000000000000000000000000000')
    const durationDays = ref(1)
    const auctions = ref([])

    const createAuctionStatus = ref('')

    const AuctionHouseFromSigner = async () => {
      const signer = await getSigner()
      const chainId = await signer.getChainId()
      if(chainId !== 4) {
        console.log("WRONG NETWROK CHANGE TO RINKYBY")
      }
      return new AuctionHouse(signer, chainId)
    }

    const createAuction = async () => {
      try {
        createAuctionStatus.value = `Creating Auction for ${tokenId.value}`
        // done from artist wallet as signer
        const auctionHouse = await AuctionHouseFromSigner()
        const zora = await getZora()
        const { auctionHouse: auctionHouseContract } = auctionHouse

        // Approve the media to be placed on auction
        const approved = await zora.fetchApproved(tokenId.value)
        if(approved !== auctionHouseContract.address){
          createAuctionStatus.value = `Approving Auction House contract to manage media...`
          const approvalTx = await zora.approve(auctionHouseContract.address, tokenId.value)

          // wait for confirmation of approval
          createAuctionStatus.value = "Confirming Approval..."
          await approvalTx.wait()

          createAuctionStatus.value = "Approval confirmed."
        }

        // Auction options
        const curator = '0x2c5E0aA28870006e2daFB2d2B8900135F8b4fCEf' // olta address
        const curatorFeePercentage = 10

        createAuctionStatus.value = "Creating Auction"
        const createAuctionTx = await auctionHouse.createAuction(
          tokenId.value,
          durationDays.value * 24 * 60 * 60,
          utils.parseUnits(reservePrice.value, "ether"),
          curator,
          curatorFeePercentage,
          auctionCurrency.value,
        )

        // Get auction from reciept
        createAuctionStatus.value = "Confirming Auction..."
        const receipt = await createAuctionTx.wait()
        console.log(receipt)
        createAuctionStatus.value = "Auction Created :)"

      } catch (error) {
        createAuctionStatus.value = `ERROR ${error.message}`
        console.error(error)
      }
    }

    const cancelAuction = async (auctionId) => {
      const auctionHouse = await AuctionHouseFromSigner()
      const cancelAuctionTx = await auctionHouse.cancelAuction(auctionId)
      console.log('canceling auction')
      cancelAuctionTx.wait()
      console.log('confirmed')
    }

    const loadOwnedMedia = async () => {
      const signer = await getSigner()
      const address = (await signer.getAddress()).toLowerCase()
      const query =  gql`{
        medias(where: {
           owner: "${address}"
        }) {
          id
          creator {
            id
          }
        }
      }`
      const chainId = await signer.getChainId()
      if(chainId === 1){
        const resp = await zoraGraph.request(query)
        return resp.medias
      }
      if(chainId === 4){
        const resp = await zoraGraphRinkeby.request(query)
        return resp.medias
      }
    }

    const loadAuctions = async () => {
      const signer = await getSigner()
      const address = (await signer.getAddress()).toLowerCase()

      const query =  gql`{
        reserveAuctions(where: {
            tokenOwner: "${address}",
        }){
          id
          status
          approved
          duration
          expectedEndTimestamp
          tokenOwner {
            id
          }
          reservePrice
          auctionCurrency{
            name
            symbol
            decimals
            id
          }
          currentBid{
            bidder {
              id
            }
            amount
            createdAtTimestamp
            transactionHash
          }
          media {
            id
          }
        }
      }`

      const chainId = await signer.getChainId()
      if(chainId === 1){
        const resp = await zoraGraph.request(query)
        return resp.reserveAuctions.reverse()
      }
      if(chainId === 4){
        const resp = await zoraGraphRinkeby.request(query)
        return resp.reserveAuctions.reverse()
      }
    }

    const refresh = async () => {
      auctions.value = await loadAuctions()
      ownedMedia.value = await loadOwnedMedia()
    }

    const canCancel = ({status, currentBid}) => ((status === 'Active' || status === 'Pending') && !currentBid)
    const isWaitingApproval = ({status, approved}) => (status === 'Pending' && !approved)

    onMounted(async () => {
      auctions.value = await loadAuctions()
      ownedMedia.value = await loadOwnedMedia()
    })

    return {
      tokenId,
      reservePrice,
      auctionCurrency,
      durationDays,
      auctions,
      createAuction,
      refresh,
      canCancel,
      isWaitingApproval,
      cancelAuction,
      ownedMedia,
      createAuctionStatus
    }
  },
})
</script>

<style lang="scss" scoped>
  .create-auction{
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 2em;
    gap: 0.1em;
  }
  .manage-auctions {
    margin: 2em;
    max-width: 800px;
  }
  .auctions {
    width: 100%;
    max-width: 880px;
    text-align: left;
    border-spacing: 0px;
    td, th{
      border-bottom: 1px solid black;
      padding: 1em 0.5em 0em 0.5em;
    }

    tr {
      &.finished{
        opacity: 0.6;
      }
    }
  }
  .refresh-button{
    position: absolute;
    right: 2em;
  }

</style>
